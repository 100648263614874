









































































import wAxios from '@/plugins/w/axios'
import { debounce } from 'debounce'

const api = {
  invite: (memberId, groupId) => wAxios.post_auth(`/v1/group-admin/groups/${groupId}/members/${memberId}/invite`),
  prefill: (params) => wAxios.get_auth('v1/group-admin/groups/members/prefill', { params })  
}

export default {

  props: {
    value: { required: true },
    groupId: { required: true }
  },

  data() {
    return {
      prefilledOptions: [],
      emailHasFocus: false
    }
  },

  computed: {
    statusText() {
      if (this.value.is_active == undefined) {
        return ''
      }
      
      if (this.value.is_active && this.value.is_invited) {
        return 'V skupine'
      } else if (!this.value.is_active && this.value.is_invited) {
        return 'Čaká na potvrdenie'
      }

      return 'Nie je pozvaný'
    },

    statusClass() {
       if (this.value.is_active == undefined) {
        return {}
      }

      if (this.value.is_active && this.value.is_invited) {
        return {'--in-group': true }
      } else if (!this.value.is_active && this.value.is_invited) {
        return {'--waiting-for-approval': true }
      }

      
      return {'--not-invited': true }
    },

    showInvite() {
      return !!this.value.user.email && this.value.is_active != undefined
    }
  },

  methods: {
    async invite() {
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Naozaj chcete znovu odoslať pozvánku pre tohto študenta?', {
          title: 'Odoslať pozvánku pre študenta?',
          okTitle: 'Odoslať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false,
        })

        if (!confirm) {
          return
        }

        await api.invite(this.value.id, this.groupId)
        const toastContent = this.$createElement(
          'div',
          {},
          [
            'Pozvánka pre študenta ',
            this.$createElement(
              'strong', 
              { class: ['w-color-primary'] }, 
              [`${this.value.name} ${this.value.surname}`]
            ),
            ' bola odoslaná na jeho email: ',
            this.$createElement(
              'strong', 
              { class: ['w-color-primary'] }, 
              [this.value.user.email]
            ),
          ]
        )
        this.$bvToast.toast(
          [toastContent],
          {
            title: 'Podarilo sa!',
            autoHideDelay: 3000,
            variant: 'a-success',
            toaster: 'b-toaster-top-center',
            solid: true

          }
        )
        this.$emit('invite')
      } catch (err) {
        this.$wToast.error(err)
      }
    },

    onEmailInput: debounce(
      async function(event) {
        if (event) {
          const { data: { data } } = await api.prefill({ email: event })
          this.prefilledOptions = data?.slice(0, 4) || []
        } else {
          this.prefilledOptions = []
        }
      }, 
      300
    ),

    focusOption(i = 0) {
      if (this.$refs.prefillButton && this.$refs.prefillButton[i]) {
        this.$refs.prefillButton[i].focus()
      } else {
        this.$refs.email.$el.focus()
        this.focusEmail()
      }
    },

    focusEmail() {
      this.emailHasFocus = true
    },

    looseFocusEmail() {
      // Hack, whatever...delay blur to capture click event
      setTimeout(() => { 
        if (
          !document.activeElement?.classList.contains('prefilled-option') &&
          document.activeElement != this.$refs.email.$el
        ) {
          this.emailHasFocus = false 
        }
      }, 100)
    },

    prefill(option) {
      this.value.user.email = option.email
      this.value.name = option.name
      this.value.surname = option.surname
      this.prefilledOptions = []
    }
  }
}
